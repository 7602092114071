var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bbzx"},[_c('div',{staticClass:"topsek"}),_c('el-tabs',{staticClass:"main",attrs:{"type":"card"},on:{"tab-click":_vm.handleClick},model:{value:(_vm.activeName),callback:function ($$v) {_vm.activeName=$$v},expression:"activeName"}},[_c('el-tab-pane',{attrs:{"label":"下载专区","name":"first"}},[_c('div',{staticClass:"biaot"},[_vm._v("文档资料")]),_c('div',{class:[{ box: true }, { clearfix: true }, { marbo: _vm.flag1 }]},[_c('div',{staticClass:"renwu renwu1"},[_c('img',{staticClass:"renwuleftimg",attrs:{"src":require("../../assets/bbzx/2.png")}}),_vm._v(" 委托加工单 ")]),_c('el-row',[_c('el-button',{staticClass:"huyi2",attrs:{"type":"primary"},on:{"click":_vm.toOne}},[_c('img',{staticClass:"xiaztup",staticStyle:{"position":"absolute","right":"40px","top":"50%","transform":"translateY(-50%)"},attrs:{"src":require("@/assets/2.2病例详情/下载按钮图标.png")}}),_vm._v(" 下载 ")])],1)],1),_c('div',{class:{
          hide: _vm.flag1,
          hide: _vm.Compatible.isqiqimei,
          hide: _vm.Compatible.isAMKQ,
          hide: _vm.Compatible.isJYYX,
          hide: _vm.Compatible.ismysj
        }},[_c('div',{staticClass:"biaot"},[_vm._v("OrthoPlus软件下载")]),_c('div',{staticClass:"box clearfix"},[_c('div',{staticClass:"renwu renwu2"},[_c('img',{staticClass:"renwuleftimg",attrs:{"src":require("../../assets/bbzx/1.png")}}),_vm._v(" OrthoPlus.exe "),_c('span',{staticStyle:{"position":"absolute","top":"-5px","vertical-align":"super","font-size":"8px"}},[_vm._v("®")])]),_c('el-row',[_c('el-button',{staticClass:"huyi2",attrs:{"type":"primary"},on:{"click":_vm.lkj}},[_c('img',{staticClass:"xiaztup",staticStyle:{"position":"absolute","right":"40px","top":"50%","transform":"translateY(-50%)"},attrs:{"src":require("@/assets/2.2病例详情/下载按钮图标.png")}}),_vm._v(" 下载 ")])],1)],1),_c('p',{staticClass:"pbq p1"},[_vm._v(" OrthoPlus是"+_vm._s(_vm.Compatible.typeLog ? '正丽科技' : '')+"自主研发的一款集提交、修改、跟踪、查看病例于一体的软件，方便医生管理病例并拟定治疗方案的在线病例管理平台。 ")])]),_c('p',{staticClass:"pbq p2",class:{
          hide: _vm.Compatible.isqiqimei,
          hide: _vm.Compatible.isAMKQ,
          hide: _vm.Compatible.isJYYX,
          hide: _vm.Compatible.ismysj
        }},[_vm._v(" 如果您在使用"+_vm._s(_vm.Compatible.typeLog ? '正丽科技' : '')+"软件时遇到任何技术问题，请查阅我们的软件帮助手册。 ")]),_c('p',{staticClass:"pbq p3",class:{
          hide: _vm.Compatible.isqiqimei,
          hide: _vm.Compatible.isAMKQ,
          hide: _vm.Compatible.isJYYX,
          hide: _vm.Compatible.ismysj
        }},[_vm._v(" 您也可以联系公司客服或当地营销人员，以获取进一步帮助。 ")])]),(!_vm.Compatible.isqiqimei && !_vm.Compatible.isAMKQ && !_vm.Compatible.isJYYX && !_vm.Compatible.ismysj)?_c('el-tab-pane',{attrs:{"label":"联系我们","name":"second"}},[_c('p',{staticClass:"pbq pp1"},[_vm._v(" 非常感谢您"+_vm._s(_vm.Compatible.typeLog ? '对正丽科技' : '')+"的关注，我们全体员工将致力于为您提供优质的服务。 ")]),_c('p',{staticClass:"pbq pp2"},[_vm._v("如果您有任何问题或需要了解更多的产品信息，请通过电话或邮件联系相应的客户经理。")]),_c('div',{staticClass:"item clearfix"},[_c('div',[_vm._v("联系方式")]),_c('div',[_c('p',{staticStyle:{"margin-bottom":"2px"}},[_vm._v("医生服务热线： 022-84846099")]),_c('p',[_vm._v("客服热线： 4007-177-977")])])]),_c('div',{staticClass:"item clearfix"},[_c('div',[_vm._v("工作时间")]),_c('div',[_c('p',[_vm._v("上午9点至下午6点，周一至周五")])])]),_c('div',{staticClass:"item clearfix"},[_c('div',{staticClass:"dizhi"},[_vm._v("地址")]),_c('div',[_c('p',{staticStyle:{"margin-bottom":"2px"}},[_vm._v("上海市闵行区黎安路999号AFC大虹桥国际11层")]),_c('p',[_vm._v("天津市东丽区空港经济区中环西路56号中科天保智谷9号楼")])])]),_c('div',{staticClass:"item clearfix"},[_c('div',[_vm._v("临床顾问")]),_c('div',[_c('p',[_vm._v("请联系您的客户经理为您预约临床支持服务")])])])]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }